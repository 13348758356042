@use "../../Styles/global" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/variables" as *;

.price-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 1rem;

    &__buffer {
        padding: 0 0.75rem 0.75rem 0.75rem;
        width: 100%;
    }

    &__header {
        @include mobileH3;
        @include background-colour;
        box-shadow: $shadow;
        padding: 0.5rem 1rem 0.5rem 1rem;
        letter-spacing: 0;
        position: relative;
        margin-left: -2rem;
        margin-right: -2rem;
    }

    &__price {
        @include mobileH2;
        padding: 0.5rem 0;
    }

    &__location {
        @include mobileP2;
    }

    &__directions {
        @include mobileH3;
    }

    &__group {
        display: flex;
        align-items: center;
    }

    &__pin {
        width: 25px;
    }
}

.price-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 40%;
    border-radius: $border-radius;
    background-color: $white;
    box-shadow: $shadow;
    padding: 1rem;
    text-align: center;
    margin: 0.75rem;
}
