@use "../../Styles/global" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/variables" as *;

.hero-container {
    &__map,
    &__roadie {
        width: 100%;
        z-index: -1;
        position: relative;
        max-height: 60svh;

        @include tablet {
            max-height: 22rem;
            object-fit: cover;
            object-position: bottom;
        }

        @include tabletXL {
            height: 20rem;
            object-fit: cover;
            object-position: bottom;
        }

        @include desktop {
            object-fit: cover;
            object-position: bottom;
        }
    }

    &__roadie {
        width: 100%;
        height: auto;
        margin-top: -20rem;

        @include tablet {
        }

        @include tabletXL {
            margin-top: -35rem;
            height: fit-content;
        }

        @include desktop {
            margin-top: -45rem;
            height: fit-content;
        }
    }
}
