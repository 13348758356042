@use "../../Styles/global" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/variables" as *;

@mixin sitNSpin($animationSpeed: 1000ms) {
    $sitNSpinSize: 10em;

    &:before {
        @include transformBefore(45);
        animation: rotateBefore $animationSpeed infinite linear reverse;
    }
    &:after {
        @include transformAfter(45);
        animation: rotateAfter $animationSpeed infinite linear;
    }
    &:before,
    &:after {
        box-sizing: border-box;
        content: "";
        display: block;
        position: fixed;

        top: 50%;
        left: 50%;
        margin-top: -0.5 * $sitNSpinSize;
        margin-left: -0.5 * $sitNSpinSize;
        width: $sitNSpinSize;
        height: $sitNSpinSize;

        transform-style: preserve-3d;
        transform-origin: 50%;
        transform: rotateY(50deg);
        perspective-origin: 50% 50%;
        perspective: 340px;

        background-size: $sitNSpinSize $sitNSpinSize;
        background-image: url("../../Assets/Icons/loader.svg");
    }
}

@mixin transformBefore($Z) {
    @include transformThis(60, 45, $Z);
}

@mixin transformAfter($Z) {
    @include transformThis(240, 45, $Z);
}

@mixin transformThis($X, $Y, $Z) {
    transform: rotateX($X + deg) rotateY($Y + deg) rotateZ($Z + deg);
}

@keyframes rotateBefore {
    from {
        @include transformBefore(0);
    }
    to {
        @include transformBefore(-360);
    }
}

@keyframes rotateAfter {
    from {
        @include transformAfter(0);
    }
    to {
        @include transformAfter(360);
    }
}

.spinner {
    width: 100%;
    height: 400%;
    background-color: rgba(0, 0, 0, 0.243);
    position: absolute;
    top: 0;
    z-index: 200;
    @include sitNSpin(750ms);

    &__text {
        text-align: center;
        color: white;
        position: relative;
        top: 14%;
    }
}
