@use "../../Styles/global" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/variables" as *;

.container {
    padding: 2rem;
}

.title {
    text-align: center;
}

.selection {
    display: flex;
    justify-content: space-evenly;
    margin: 1rem 0;

    &__button {
        @include background-colour;
        padding: 0.5rem;
        border: none;
        border-radius: $border-radius;
        cursor: pointer;
        box-shadow: $shadow;
        margin: 0 0.5rem;
    }
}

.search {
    margin-bottom: 1.5rem;

    &__input {
        width: 80%;
        flex: 1;
        padding: 0.25rem;
        border: 1px solid #ccc;
        border-radius: $border-radius;
        margin: 0.25rem;
    }

    &__select {
        width: 80%;
        padding: 0.25rem;
        margin: 0.25rem;
        border: 1px solid #ccc;
        border-radius: $border-radius;
        @include mobileP1;
    }

    &__button {
        padding: 0.25rem;
        border: none;
        border-radius: $border-radius;
        cursor: pointer;
        margin: 0.25rem;
    }
}

.search-dropdown {
    background-color: white;

    &__item {
        padding: 0.5rem;

        &:hover {
            @include cta-background;
            cursor: pointer;
        }
    }
}

.results {
    background-color: white;
    padding: 2rem;
    border-radius: $border-radius;
    box-shadow: $shadow;
    margin-bottom: 2rem;

    &__no-results {
        text-align: center;
    }
}

.form {
    background-color: white;
    padding: 2rem;
    border-radius: $border-radius;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
    margin-top: 1rem;

    &__title {
        margin-bottom: 1rem;
    }

    &__input,
    &__textarea {
        padding: 1rem;
        margin: 0.5rem 1rem;
        border: 1px solid #ccc;
        border-radius: $border-radius;
        box-sizing: border-box;
    }

    &__checkbox {
        margin: 0 0.75rem;
    }

    &__button {
        padding: 1rem 2rem;
        margin: 1rem;
        border: none;
        border-radius: $border-radius;
        cursor: pointer;
    }

    &__select {
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: $border-radius;
        margin: 0.5rem 0;
    }
}

.form-group {
    display: flex;
    flex-direction: column;

    &--featured {
        flex-direction: row;
        margin-bottom: 1rem;
    }
}

.add-type {
    display: flex;
    flex-direction: column;

    &__label {
        margin: 0 1rem;
    }

    &__input {
        margin: 0 0.5rem;
        cursor: pointer;
    }
}

.error-container {
    padding: 1rem;
    margin: 0.5rem;
}

.success-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.278);

    &__font {
        background-color: $white;
        padding: 4rem 1rem;
        border-radius: $border-radius;
        text-align: center;
        position: relative;
        top: 50%;
        margin: 0 3rem;
    }
}
