@use "../../Styles/global" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/variables" as *;

@keyframes slideInFromLeft {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

// Reformat - send to components
.bp-search-form {
    display: flex;
    flex-direction: column;

    &__input {
        padding: 0.5rem 0.5rem 0.5rem 1rem;
        margin: 0 1rem 0.5rem 1rem;
    }

    &__label {
        @include mobileH2;
        padding: 2rem 1rem 0 1rem;
        margin-bottom: 0.5rem;
    }

    &__button {
        @include mobileH3;
        background: linear-gradient(45deg, rgb(255, 165, 0) 0%, rgb(255, 204, 0) 100%);
        padding: 0.25rem;
        margin: 0 3rem 0.5rem 3rem;
        box-shadow: $shadow;

        &:active {
            @include background-colour;
            box-shadow: $shadow;
            color: $white;
        }

        &:disabled {
            @include background-colour;
        }
    }
}

.bp-search-dropdown {
    padding-top: 0.5rem;
    margin: -0.5rem 1rem 0 1rem;
    background-color: white;
    max-height: 125px;
    overflow-y: scroll;

    &__item {
        padding: 0.5rem;

        &:hover {
            cursor: pointer;
            @include cta-background;
        }
    }
}

.bp-alph {
    &__header {
        margin: 1rem;
    }
    &__list {
        margin: 0 1rem;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    &__item {
        @include mobileP1;
        padding: 0 0.45rem;
        margin-bottom: 0.25rem;

        &:hover {
            cursor: pointer;
            text-decoration: underline blue;
            color: blue;
        }

        &--active {
            text-decoration: underline blue;
            color: blue;
            padding: 0 0.45rem;
            margin-bottom: 0.25rem;
            @include cta-background;
            height: 90%;
        }

        @include tablet {
            @include mobileH3;
        }

        @include desktop {
            @include desktopH2;
        }
    }
}

.bp-contact {
    @include mobileP2;
    color: blue;
    text-align: end;
    padding-right: 2rem;

    &:hover {
        text-decoration: underline;
    }
}

.bp-display-container {
    padding: 0 1rem 1rem 1rem;

    &__no-results {
        margin: 2rem 0;
        text-align: center;
    }
}

.business-card {
    padding: 0.25rem;
    box-shadow: $shadow;
    border-radius: $border-radius;
    padding: 1rem;
    background-color: aliceblue;
    margin: 1rem;
    animation: slideInFromLeft 1s ease-in-out forwards;

    &__name,
    &__location,
    &__description {
        @include desktopP2;
        text-decoration: underline;
    }

    &__body {
        margin-bottom: 0.5rem;
        @include mobileP1;
    }

    &__link {
        color: blue;
    }
}

.pagination-upper {
    display: flex;
    justify-content: space-between;

    &__first,
    &__last {
        padding: 0 1rem;
        font-size: small;
        background: none;

        &:hover {
            color: blue;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

.pages {
    display: flex;
    justify-self: center;
    height: 3rem;
    display: flex;
    justify-content: space-evenly;

    &__next,
    &__previous,
    &__first,
    &__last {
        margin: 0.5rem 1rem;
        background: none;
        font-weight: 700;
        font-size: 1rem;

        &:hover {
            color: blue;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
