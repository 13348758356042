@use "./mixins" as *;
@use "./variables" as *;

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    @include background-colour;
    font-family: Barlow, Geneva, Tahoma, sans-serif;
}

ol,
ul,
menu {
    list-style: none;
}

a {
    text-decoration: inherit;
    color: inherit;
}

fieldset,
button,
input[type="button"] {
    border: none;
}

input,
textarea,
select,
button {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    text-decoration: none;
    border: unset;
}

.main {
    margin-top: 8rem;
}

.input-error {
    @include mobileP3;
    background-color: #ffcccc;
    animation: shake 0.5s;
    padding: 0.25rem;

    @keyframes shake {
        0%,
        100% {
            transform: translateX(0);
        }
        10%,
        30%,
        50%,
        70%,
        90% {
            transform: translateX(-10px);
        }
        20%,
        40%,
        60%,
        80% {
            transform: translateX(10px);
        }
    }
}

.error-message {
    @include mobileP3;
    color: red;
}
