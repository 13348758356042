@use "../../Styles/global" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/variables" as *;

.about-container {
    &__div-header {
        background-color: rgba(255, 255, 255, 0.274);
        padding: 1rem;
    }

    &__div {
        margin: 1rem;
        padding: 1rem;
        background-color: rgba(255, 255, 255, 0.274);
    }

    &__div-small {
        padding-top: 0.5rem;
    }

    &__list {
        padding-left: 2rem;
    }

    &__header {
        @include mobileH1;
    }

    &__subhead {
        @include mobileH2;
    }

    &__bullet-title {
        @include mobileH3;
        padding-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    &__body {
        @include mobileP1;
    }

    &__sub-bod {
        @include mobileP2;
    }

    &__link {
        padding-left: 0.25rem;
        color: red;

        &:hover {
            cursor: pointer;
        }
    }
}
