@use "../../Styles/global" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/variables" as *;

@keyframes fill-border {
    0% {
        border-color: transparent;
    }
    25% {
        border-bottom-color: #3a82b5be;
    }
    50% {
        border-right-color: #3a82b5be;
    }
    75% {
        border-top-color: #3a82b5be;
    }
    100% {
        border-left-color: #3a82b5be;
    }
}

.search-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 3;
}

.search-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;

    &__header {
        @include mobileH2;
        border-radius: $border-radius;
        width: 100%;
        text-align: center;
        margin: 0.5rem;
        padding: 0.5rem;
        box-shadow: $shadow;
        background-color: $white;
        border: 1.75px solid transparent;
        transition: border-color 0.6s step-end;

        &:hover {
            cursor: pointer;
            border-color: #3a82b5be;
            animation: fill-border 1s steps(80) forwards;
        }
    }

    &__label {
        width: 100%;
    }

    &__input {
        width: 100%;
        text-align: left;
        border-radius: $border-radius;
        padding: 0.5rem 0.5rem 0.5rem 3rem;
        border-radius: $border-radius;
        background-image: url("../../Assets//Icons/search.svg");
        background-repeat: no-repeat;
        background-position: 0.8rem;
        box-shadow: $shadow;
    }
}

.loading {
    position: absolute;
    top: 3rem;
    height: 200px;
    width: 30%;
    background-color: aliceblue;
}
