@use "../../Styles/global" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/variables" as *;

.article-add {
    display: flex;
    justify-content: center;

    &__button {
        @include cta-background;

        padding: 0.5rem;
        border-radius: $border-radius;

        &:hover {
            cursor: pointer;
            scale: 1.1;
        }
    }

    &__input {
        margin: 0 0.5rem;
        border-radius: $border-radius;
        padding: 0.5rem;
    }
}

.articles-intro {
    padding: 1rem;
    @include background-colour;
    margin: 1rem;

    &__header {
        @include mobileH1;
    }

    &__link {
        text-decoration: underline;
    }

    &__body {
        margin: 1rem 0;
        @include mobileP1;
    }
}
