@use "../../Styles/global" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/variables" as *;

.mapbox-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    padding: 10%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.8);

    @include tablet {
        padding: 7%;
    }

    @include desktop {
        padding: 5%;
    }
}

.close-map {
    margin-left: -2px;
    @include background-colour;
    border: 2px inset black;
    border-bottom: none;
    font-size: 1.25rem;
    padding: 0 2rem;
    cursor: pointer;

    @include tablet {
        font-size: 1.5rem;
    }

    &__x {
        &:hover {
            color: $white;
        }
        &:focus {
            color: $white;
        }
    }
}
