@use "../../Styles/global" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/variables" as *;

.contact-container {
    padding: 1rem 0;
    width: 70%;
    background-color: white;
    margin: 0 auto;
    box-shadow: $shadow;

    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;

    &.visible {
        opacity: 1;
        transform: translateY(0);
    }

    &__failed,
    &__sent {
        width: 70%;
        height: 22rem;
        background-color: white;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top: 10rem;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;

        @include tabletXL {
            height: 25rem;
        }
    }

    &__failed {
        color: red;
    }
}

.contact-container-top {
    @include background-colour;
    @include mobileH3;
    margin: 0 auto;
    text-align: center;
    height: 60px;
    width: 80%;
    z-index: 5;
    box-shadow: $shadow;
    align-content: center;

    &__img {
        width: 30px;
        height: 30px;
    }

    &__header {
        @include mobileP1;
    }
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;

    &__section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 70%;

        &--text {
            margin-top: 1rem;
        }
    }

    &__input,
    &__text-area {
        border-bottom: 1px solid rgba(11, 97, 116, 0.453);
        padding: 0.5rem;
        margin: 0.25rem 0;
        font-size: 0.75rem;
        transition: border-color 0.3s, background-color 0.3s, transform 0.3s;
        width: 100%;

        @include tabletXL {
            font-size: 1rem;
        }
    }

    &__button {
        @include button-style-grey;
        margin-top: 1rem;
        padding: 1rem;

        &:hover,
        &:focus {
            color: $black;
        }

        &:active {
            transform: scale(1.1);
        }
    }
}

.contact-container-text {
    padding: 2rem;

    &__header {
        margin-bottom: 1rem;
        @include mobileP1;
    }

    &__body {
        @include mobileP2;
    }
}

.middle {
    display: none;
}
