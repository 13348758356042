@use "../../Styles/global" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/variables" as *;

.footer-container {
    background-image: url(../../Assets/Icons/footerBackground.png);
    background-size: cover;
    padding: 5rem 1rem 0 1rem;
    position: relative;
    bottom: 0;
    width: 100%;

    @include tabletXL {
        padding-top: 6rem;
    }

    @include desktop {
        padding-top: 9rem;
    }

    &__title {
        padding-left: 1rem;
    }

    &__leftright {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
}

.footer-social {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 345px) {
        width: 50%;
    }

    &__title {
        @include mobileH3;
        margin-bottom: 0.75rem;
        text-align: right;
        padding-left: 2rem;

        &:hover {
            scale: 1.1;
        }

        @include tabletXL {
            margin-bottom: 1rem;
        }
    }

    &__icon-container {
        display: flex;
        justify-content: flex-end;
    }

    &__icon {
        filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.5));
        padding: 0 0.25rem 0 0.25rem;

        &:hover {
            transition: transform 0.5s ease-in-out;
            transform: translateY(-5px);
            filter: drop-shadow(4px 4px 3px rgba(0, 0, 0, 0.4));
        }

        @media screen and (max-width: 345px) {
            padding: 0;
            scale: 0.7;
        }
    }
}

.leftright {
    &__link {
        &:hover {
            text-decoration: underline;
        }
        @include mobileH3;
        line-height: 2rem;

        &--nowrap {
            @include mobileH3;
            text-wrap: nowrap;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__copyright {
        @include mobileP3;
        padding-top: 2rem;

        &--small {
            @include mobileP3;
        }
    }
}
