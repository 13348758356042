@use "../../Styles/global" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/variables" as *;

.reset-container {
    height: 100%;
    padding: 1rem;

    &__header {
        @include mobileH1;
    }
}

.reset-form {
    margin: 2rem 0;
    display: flex;
    flex-direction: column;

    &__input-container {
        display: flex;
        flex-direction: column;
        margin: 1rem 0;
    }

    &__label {
        padding: 0.5rem;
    }

    &__input,
    &__submit {
        padding: 0.5rem;
        border-radius: $border-radius;
    }

    &__submit {
        margin-top: 0.5rem;
        box-shadow: $shadow;
        width: 180px;

        &:hover {
            scale: 1.05;
            cursor: pointer;
        }

        &:active {
            @include background-colour;
        }
    }
}

.forgot-error-message {
    background-color: aliceblue;
    padding: 2rem;
}
