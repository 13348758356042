@use "../../Styles/global" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/variables" as *;

///////////////
//// * Position and sizing of burger button */
.bm-burger-button {
    position: relative;
    width: 36px;
    height: 27px;
    right: 0.125rem;
    top: 0.125rem;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: $darkGradient;
    border-radius: $border-radius;
    box-shadow: 1px 1px 1px 1px rgba(255, 255, 255, 0.163);
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #1b4d79;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: $white;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
    top: 0;
}

/* General sidebar styles */
.bm-menu {
    background: $darkGradient;
    padding-top: 2rem;
    font-size: 1.15em;
    text-align: end;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #1b4d79;
}

/* Wrapper for item list */
.bm-item-list {
    color: $close-button-background;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: end;
}

/* Individual item */
.bm-item {
    display: inline-block;
    padding: 1rem;

    &:hover {
        text-decoration: underline;
    }
}

/* Styling of overlay */
.bm-overlay {
    background: $darkGradient;
    height: auto !important;
}
