@use "../../Styles/global" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/variables" as *;

.article {
    padding: 2rem;
    background-color: $white;
    margin: 1rem;
    border-radius: $border-radius;
    box-shadow: $shadow;

    &__image {
        width: 100%;
        height: 200px;
        object-fit: cover;
        position: relative;
    }

    h1 {
        color: #2c3e50;
        margin: 1.5rem 0;
        @include mobileH1;
        text-align: center;
    }

    h2 {
        margin-bottom: 1.2rem;
        @include mobileH2;
    }

    h3 {
        @include mobileH2;
    }
    h4 {
        margin-top: 0.5rem;
        @include mobileH3;
    }

    p {
        @include mobileP1;
        margin: 0.75rem 0 1.75rem 0;
    }

    ul {
        list-style-type: disc;
        padding-left: 2rem;
        margin-bottom: 1.5rem;

        li {
            font-size: 1.125rem;
            color: #444;
            line-height: 1.5;
        }
    }

    strong {
        font-weight: bold;
    }

    em {
        font-style: italic;
    }
}

.description {
    box-shadow: $shadow;
    padding: 1.5rem;
}
