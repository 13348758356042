@use "../../Styles/global" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/variables" as *;

.article-edit {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__header {
        @include mobileH1;
    }

    &__pic-input {
        margin: 1rem;
        padding: 0.25rem;
    }

    &__header {
        margin: 1rem;
    }

    &__text-area {
        padding: 2rem;
        width: 90%;
    }

    &__button {
        margin-top: 1rem;
        width: 40%;

        @include button-style-grey;
        box-shadow: $shadow;

        &:hover {
            box-shadow: $shadow;
            @include cta-background;
        }
    }
}
