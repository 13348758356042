@use "../../Styles/global" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/variables" as *;

.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__image {
        width: 90%;

        @include tabletXL {
            width: 70%;
        }

        @include desktop {
            width: 50%;
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
    }

    &__button {
        padding: 0.5rem 0.75rem;
        margin: 0 0.5rem;
        border-radius: $border-radius;
        @include cta-background;
        box-shadow: $shadow;
        transition: transform 0.3s ease, box-shadow 0.3s ease;

        &:hover {
            transform: translateY(-5px);
            cursor: pointer;
        }

        @include tabletXL {
            margin: 0 2rem;
        }

        @include desktop {
            margin: 0 4rem;
        }
    }
}
