@use "variables" as *;

@mixin tablet {
    @media (min-width: $mobile-breakpoint) {
        @content;
    }
}
@mixin tabletXL {
    @media (min-width: $tablet-breakpoint) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: $desktop-breakpoint) {
        @content;
    }
}

@mixin mobileH1 {
    font-size: 1.75rem;
    line-height: 2.25rem;
    font-weight: 500;
}

@mixin mobileH2 {
    font-size: 1.5rem;
    line-height: 1.55rem;
    font-weight: 500;
}
@mixin mobileH3 {
    font-size: 1rem;
    line-height: 1.125rem;
    font-weight: 500;
    letter-spacing: 0.05rem;
}

@mixin mobileP1 {
    font-size: 0.9375rem;
    line-height: 1.625rem;
    font-weight: 400;
}

@mixin mobileP2 {
    font-size: 0.8rem;
    line-height: 1.25rem;
    font-weight: 400;
}

@mixin mobileP3 {
    font-size: 0.6875rem;
    line-height: 1rem;
    font-weight: 400;
}

@mixin mobileP4 {
    font-size: 0.6875rem;
    line-height: 0.75rem;
    font-weight: 400;
    letter-spacing: 1;
}

@mixin desktopH1 {
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 600;
}

@mixin desktopH2 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
}

@mixin desktopH3 {
    font-size: 0.875rem;
    line-height: 1.375rem;
    font-weight: 600;
}

@mixin desktopP1 {
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
}

@mixin desktopP2 {
    font-size: 0.875rem;
    line-height: 1.375rem;
    font-weight: 400;
}

@mixin desktopP3 {
    font-size: 0.75rem;
    line-height: 1.125rem;
    font-weight: 400;
}

@mixin background-colour {
    background: rgb(180, 220, 245);
    background: $gradient;
}

@mixin cta-background {
    background: linear-gradient(45deg, rgb(255, 165, 0) 0%, rgb(255, 204, 0) 100%);
}

@mixin input-hide {
    background-color: $close-button-background;
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

@mixin button-style-grey {
    padding: 1rem 0 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;

    &:hover,
    &:focus {
        @include background-colour;
        cursor: pointer;
    }

    &:active {
        color: $white;
    }

    @include tabletXL {
        align-self: center;
        width: 50%;
    }
}

@mixin button-style-grey-small {
    position: absolute;
    height: 35px;
    width: 80px;
    align-self: flex-end;
    @include mobileP1;

    &:hover,
    &:focus {
        @include background-colour;
        cursor: pointer;
    }

    &:active {
        color: $white;
    }
}
