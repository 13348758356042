@use "../../Styles/global" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/variables" as *;

.forgot-container {
    margin: 0 auto;
    width: 70%;
    background-color: aliceblue;
    padding: 1rem;

    &__header {
        @include mobileH2;
    }
}

.forgot-form {
    padding: 0.25rem;

    &__header {
        @include mobileH3;
        margin: 1rem 0;
    }

    &__input {
        margin-bottom: 0.5rem;
        border-bottom: 1px solid rgba(11, 97, 116, 0.453);
        width: 100%;
        padding: 0.25rem;
    }

    &__body {
        @include mobileP3;
    }

    &__close-button {
        height: 35px;
        width: 65px;
        align-self: flex-end;
        @include mobileP1;
        margin-top: 0.5rem;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        @include background-colour;

        &:hover,
        &:focus {
            box-shadow: $shadow;
            cursor: pointer;
        }

        &:active {
            color: $white;
        }

        &--send {
            margin-bottom: 0.5rem;
            width: auto;
            @include background-colour;
        }
    }
}

.forgot-status-message {
    padding: 1rem;
    @include mobileH3;
}
