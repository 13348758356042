@use "../../Styles/global" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/variables" as *;

.report-price-form {
    @include mobileP1;
    margin-top: 1rem;

    &__container {
        display: flex;
    }

    &__header {
        @include mobileP4;
    }

    &__dollar {
        margin-right: 0.25rem;
    }

    &__input {
        width: 95%;

        border: 2px solid rgba(179, 179, 179, 0.347);
        border-style: inset;
    }

    &__submit {
        @include background-colour;
        padding: 0.25rem;
        border-radius: $border-radius;
        margin-top: 0.5rem;
        margin-left: 0.5rem;
        margin-bottom: 0.5rem;

        &:hover {
            scale: 1.1;
            cursor: pointer;
        }
    }
}

.price-update-success {
    background-color: aliceblue;
    padding: 1rem;
    height: 5rem;
    position: absolute;
    z-index: 10;
}
