@use "../../Styles/global" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/variables" as *;

.signin-container-bottom {
    height: 440px;
    width: 70%;
    background-color: $white;
    display: flex;
    align-self: center;
    padding: 1rem;
}

.signup-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__input--email,
    &__input--password {
        border-bottom: 1px solid rgba(11, 97, 116, 0.453);
        padding: 0.5rem;
        margin: 0.25rem;
        width: 80%;
        font-size: 0.75rem;
        transition: border-color 0.3s, background-color 0.3s, transform 0.3s;

        @include tabletXL {
            font-size: 1rem;
        }
    }

    &__img {
        height: 25px;
        width: 25px;
        margin-left: 1rem;
        margin-top: 0.5rem;
    }

    &__button {
        @include button-style-grey;

        &--icon {
            padding-left: 0.5rem;
            height: 35px;
            width: 35px;
            &:active {
                filter: brightness(0) invert(1);
            }
        }
    }

    &__forgot {
        padding-top: 1rem;
        @include mobileP3;
        &:hover {
            cursor: pointer;
            color: blue;
        }
    }

    &__field {
        display: flex;
        align-items: flex-start;
        // justify-content: space-evenly;
        margin-left: -1rem;

        @include tabletXL {
            margin-left: -1.5rem;
        }

        &--pad {
            margin-left: 1rem;
        }
    }

    &__close-button {
        @include button-style-grey-small;
    }

    &__label {
        @include input-hide;
    }
}

.register-success-message {
    position: absolute;
    top: 25%;
    left: 13%;
    width: 75%;
    height: 50%;
    background-color: aliceblue;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius;
    box-shadow: $shadow;
    padding: 1rem;
    @include mobileH3;
    text-align: center;
}
