@use "../../Styles/global" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/variables" as *;

.business-details-back {
    margin-left: 1rem;
    background: none;
    box-shadow: $shadow;
    padding: 0.025rem 1.5rem;
    border-radius: $border-radius;
    @include background-colour;

    &:hover {
        cursor: pointer;
        color: blue;
    }
}
