@use "../../Styles/global" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/variables" as *;

.login-overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: 2000;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.login-container-bottom {
    height: 320px;
    width: 70%;
    background-color: $white;
    display: flex;
    align-self: center;
}

.login-container-top {
    @include background-colour;
    margin-bottom: -2rem;
    height: 60px;
    width: 60%;
    display: flex;
    align-self: center;
    justify-content: space-between;
    z-index: 10;
    box-shadow: $shadow;
}

.top-left__text,
.top-right__text {
    position: absolute;
    top: -0.25rem;
}

.top-left,
.top-right {
    display: flex;
    width: 50%;
    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.163);
    position: relative;

    &::before,
    &::after {
        content: "";
        position: absolute;
        background: rgba(0, 0, 0, 0.205);
        transition: all 0.3s ease;
        width: calc(50% - 2px);
    }

    &::before {
        bottom: 0;
        right: 0;
        width: 0px;
        height: 1px;
    }

    &::after {
        right: 0;
        bottom: 0;
        width: 2px;
        height: 0;
    }

    &:hover::before {
        width: 100%;
    }

    &:hover::after {
        height: 100%;
    }

    &__img {
        padding-top: 0.25rem;
        width: 39px;
        height: 39px;
    }

    &__text {
        @include mobileP1;
        margin-top: 2rem;
        padding-bottom: 1rem;
        align-self: center;
        text-align: center;
        position: relative;
        cursor: pointer;

        @include tablet {
            padding: 0 1rem 0 1rem;
        }

        @include tabletXL {
            padding: 0 3rem 0 3rem;
        }

        @include desktop {
            margin: 0 auto;
            padding-top: 2rem;
        }
    }
}

.top-left {
    &:hover {
        padding-bottom: 0.25rem;
        color: rgb(11, 97, 116);
    }

    &__text {
        margin-bottom: 0.5rem;
    }
}
.top-right {
    flex-direction: row-reverse;

    &:hover {
        padding-bottom: 0.25rem;
        color: rgb(11, 97, 116);
    }

    &__text {
        margin-bottom: 0.5rem;

        &:active {
            color: $black;
        }
    }
}

.login-container-bottom {
    padding: 5rem 2rem 2rem 2rem;
}

.login-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    top: -2rem;

    &__input--email,
    &__input--password {
        border-bottom: 1px solid rgba(11, 97, 116, 0.453);
        padding: 0.5rem;
        margin: 0.25rem;
        width: 80%;
        font-size: 0.75rem;
        transition: border-color 0.3s, background-color 0.3s, transform 0.3s;

        @include tabletXL {
            font-size: 1rem;
        }
    }

    &__img {
        height: 25px;
        width: 25px;
        margin-left: 1rem;
        margin-top: 0.5rem;
    }

    &__button {
        @include button-style-grey;
        box-shadow: $shadow;

        &--icon {
            padding-left: 0.5rem;
            height: 25px;
            width: 25px;

            &:active {
                filter: brightness(0) invert(1);
            }
        }
    }

    &__forgot {
        background: none;
        width: fit-content;
        margin-top: 0.5rem;
        @include mobileP3;
        &:hover {
            cursor: pointer;
            color: blue;
        }
    }

    &__field {
        display: flex;
        margin-left: -1rem;

        @include tabletXL {
            margin-left: -1.5rem;
        }
    }

    &__close-button {
        @include button-style-grey-small;
        box-shadow: $shadow;
    }

    &__label {
        @include input-hide;
    }
}

.login-success-message {
    position: absolute;
    top: 25%;
    left: 13%;
    width: 75%;
    height: 50%;
    background-color: aliceblue;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius;
    box-shadow: $shadow;
    @include mobileH3;
    text-align: center;
}
