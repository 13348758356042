@use "../../Styles/global" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/variables" as *;

.blog-container {
    padding: 1rem;
    margin: 1rem;
    border-radius: $border-radius;
    box-shadow: $shadow;
    background-color: $white;

    &__image {
        border-radius: $border-radius;
        height: 10rem;
        width: 100%;
        object-fit: cover;
        box-shadow: $shadow;

        @include tabletXL {
            width: 300px;
        }
    }

    &__title {
        margin: 0.75rem 0;
        @include mobileH2;
    }

    &__body {
        @include mobileP3;
    }

    &__link {
        @include mobileP1;
        text-decoration: underline;
        color: blue;

        &:hover {
            cursor: pointer;
            font-weight: bold;
        }
    }
}
