@use "../../Styles/global" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/variables" as *;

.business-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.274);
    margin-bottom: 1rem;

    &__header {
        padding: 1rem;
        @include mobileH2;
    }

    &__subhead {
        padding: 1rem;
        text-align: center;
    }
}

.businesse-cards-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}

.businesse-card {
    background-color: aliceblue;
    box-shadow: $shadow;
    margin: 0.5rem;
    padding: 1rem;
    border-radius: $border-radius;
    width: 20%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: center;

    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;

    &.visible {
        opacity: 1;
        transform: translateY(0);
    }

    @include tabletXL {
        height: 8rem;
    }

    &:hover {
        transform: translateY(-5px);
        cursor: pointer;
    }

    &__img {
        height: 4rem;
        color: black;
        fill: black;
    }

    &__header {
        text-align: center;
        @include mobileP2;

        @include tablet {
            padding-top: 0.5rem;
            @include mobileP1;
        }
    }

    &__all {
        @include mobileP3;

        @include tabletXL {
            @include mobileH3;
        }

        &:hover {
            color: blue;
            cursor: pointer;
        }
    }
}

.business-search-form {
    padding: 0.5rem;

    &__button,
    &__input {
        padding: 0.25rem;
    }

    &__input {
        border-radius: $border-radius 0 0 $border-radius;

        &:focus {
            outline: none;
        }
    }

    &__button {
        @include cta-background;

        border-radius: 0 $border-radius $border-radius 0;

        &:hover {
            cursor: pointer;
        }
    }
}

.bp-search-dropdown {
    width: 90%;

    &__item {
        &:hover {
            box-shadow: $shadow;
            cursor: pointer;
        }
    }

    &__exit {
        text-align: center;
        background: $button-waiting;
        box-shadow: $shadow;

        &:hover {
            background: $gradient;
            cursor: pointer;
        }
    }
}
