$desktop-breakpoint: 1280px;
$tablet-breakpoint: 768px;
$mobile-breakpoint: 480px;

$black: #000000;
$white: #ffffff;

$border-radius: 10px;
$shadow: 0px 2px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 9px 0 rgba(0, 0, 0, 0.19);

$gradient: linear-gradient(45deg, rgb(187, 220, 241) 0%, rgb(147, 204, 239) 76%);
$button-waiting: rgba(202, 202, 202, 0.605);
$close-button-background: rgb(239, 239, 239);
$darkGradient: linear-gradient(45deg, #3a81b5 0%, #1b4d79 76%);
