@use "../../Styles/global" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/variables" as *;

.home-about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-about-card {
    width: 90%;
    margin: 1rem 2rem 0 2rem;
    text-align: center;
    border-radius: $border-radius;
    padding: 1rem;

    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;

    &.visible {
        opacity: 1;
        transform: translateY(0);
    }

    &__header {
        @include mobileH2;
    }

    &__body {
        @include mobileP1;
        margin-top: 0.5rem;
    }

    &__link {
        padding: 0 0.5rem;

        &:hover {
            text-decoration: underline;
            color: blue;
        }
    }
}

.bold {
    font-weight: bolder;
}

.homepage-articles {
    margin: 0.75rem;
    max-width: 100%;
    width: 100%;

    &__header {
        @include mobileH1;
        margin-top: 1rem;
        padding-top: 1rem;
        text-align: center;
        color: $shadow;

        &:hover {
            color: blue;
            cursor: pointer;
            scale: 1.05;
        }
    }

    &__container {
        @include tabletXL {
            display: flex;
            overflow: scroll;
            -webkit-overflow-scrolling: touch;
        }
    }

    &__link {
        color: blue;
        text-align: end;
        margin-right: 1rem;
        @include mobileP1;

        &:hover {
            font-weight: 900;
            cursor: pointer;
        }
    }

    &__none {
        border: 1px solid black;
        padding: 0.5rem 2rem;
        text-align: center;
        margin: 1rem 4rem;

        @include tabletXL {
            margin: 0 auto;
        }
    }
}
