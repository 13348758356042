@use "../../Styles/global" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/variables" as *;

.privacy {
    padding: 2rem;

    &__header {
        @include mobileH1;
        margin-bottom: 1rem;
    }

    &__subhead {
        @include mobileH2;
        padding: 1rem 0.25rem;
        margin-top: 0.5rem;
    }

    &__subheadxs {
        @include mobileP1;
        margin: 1rem 0;
    }

    &__body {
        @include mobileP1;
    }

    &__list {
        padding: 1rem 0;
    }

    &__item {
        @include mobileP3;
        padding: 0.5rem;
    }

    &__link {
        margin: 0.5rem 0;
        color: red;
        font-weight: bolder;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    &__updated {
        @include mobileP3;
    }
}
