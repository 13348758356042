@use "../../Styles/global" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/variables" as *;

.header-container {
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.header {
    width: 100%;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    transition: transform 0.3s ease-in-out;
    background-color: rgb(151, 202, 241);
}

.header-hidden {
    transform: translateY(-100%);
}

.top-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__left-menu,
    &__right-menu {
        margin: 1rem;
        height: 1.5rem;
        width: 4rem;
    }

    &__left-menu {
        @include cta-background;
        border-radius: $border-radius;
        box-shadow: $shadow;
        color: $black;

        &:hover {
            scale: 1.1;
            cursor: pointer;
        }
    }

    &__logo {
        width: 3.5rem;
    }
}

.bottom-container {
    display: flex;
    justify-content: center;

    &__header {
        @include mobileH1;
        margin-top: -0.5rem;
    }
}
