@use "../../Styles/global" as *;
@use "../../Styles/mixins" as *;
@use "../../Styles/variables" as *;

.pin-modal-container {
    position: absolute;
    top: 25%;
    left: 12%;
    height: fit-content;
    width: 200px;
    background-color: aliceblue;
    border-radius: $border-radius;
    box-shadow: $shadow;

    &__buffer {
        padding: 0 0.75rem 0.75rem 0.75rem;
    }

    &__close-map {
        @include mobileP4;
        padding: 0.5rem;
        border-radius: $border-radius;
        background: none;

        &:hover {
            scale: 1.1;
            cursor: pointer;
        }
    }

    &__name {
        @include mobileH3;
        @include background-colour;
        box-shadow: $shadow;
        padding: 0.5rem 1rem 0.5rem 1rem;
        letter-spacing: 0;
        position: relative;
        margin-left: -1rem;
        margin-right: -1rem;
    }

    &__location {
        padding: 0.5rem 0 0.25rem 0;
        @include mobileP4;
    }

    &__phone {
        @include mobileP1;
        line-height: normal;

        &--colour {
            color: blue;
        }
    }

    &__price {
        @include mobileH3;
        line-height: 1.5rem;
        padding-top: 0.5rem;
    }

    &__updated-at {
        @include mobileP2;
        line-height: normal;
    }

    &__directions {
        display: flex;
        justify-content: center;
        padding-top: 1rem;
        color: blue;

        &:hover {
            scale: 1.1;
            cursor: pointer;
        }
    }
}

.favourite {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    &__header {
        @include mobileP1;
        background: none;

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    &__yes {
        width: 25px;
        height: 25px;

        &:hover {
            scale: 1.3;
            cursor: pointer;
        }
    }
}
